import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../../components/blocks/mdx-layout";
import { SEO } from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Photography" breadcrumbListItems={[{
      name: `Art`,
      url: `/art`
    }, {
      name: `Photography`,
      url: `/art/photography`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "photography"
    }}>{`Photography`}</h1>
    <p>{`Whenever I can I combine my love for hiking with photography. And often times it pushes me further by reaching higher mountains, waking up earlier, and exploring nature more. I primarily shoot landscape photography with occasional street photography.`}</p>
    <p>{`I currently use a FujiFilm X-T100 together with these lenses:`}</p>
    <ul>
      <li parentName="ul">{`XF16-80mm F4 R OIS WR (When I need flexibility both in the city & landscape)`}</li>
      <li parentName="ul">{`XF16mm F2.8 R WR (My favourite lens to shoot landscape)`}</li>
      <li parentName="ul">{`XF35mm F2 R WR (Default lens)`}</li>
    </ul>
    <p>{`I love the color science and look of FujiFilm! I process all images with Capture One. Below you can find the favourite photos from my trips.`}</p>
    <h2 {...{
      "id": "lake-constance--alps"
    }}>{`Lake Constance & Alps`}</h2>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51550966055/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51550966055_e05122c90c_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Look from Bregenz (top perspective from mountain) onto Lake Constance. In the foreground the forest, from the left side the lake spans across the image and on the right the cities directly at the lake are visible."
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51549250777/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51549250777_11139cab90_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "An orange-brown butterfly sitting on a leaf."
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51550054541/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51550054541_0203aa700a_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "A red-white dahlia."
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51550289878/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51550289878_99d6fb2720_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Foreground shows a green (grass) mountain ridge with a couple of trees and a small hut further down. The background is the silhouette of the Alps (German side). The picture itself was taken on a mountain looking at the german / austrian alps."
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51550288998/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51550288998_4d05444e28_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Series of mountain ridges, one after another with fading visibility further it goes to the background."
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51550055556/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51550055556_95071de5ce_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Look at the Säntis (Switzerland) from Hochgrat station."
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51550290328/in/dateposted-public/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "data-drop-shadow": "true",
        "src": "https://live.staticflickr.com/65535/51550290328_4914df31f6_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Look from Hochgrat at the Säntis mountain and Lake Constance (covered with mist)."
      }}></img>
    </a>
    <h2 {...{
      "id": "los-angeles--san-francisco"
    }}>{`Los Angeles & San Francisco`}</h2>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51169934972/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51169934972_81dbcfb022_h.jpg",
        "width": 1067,
        "height": 1600,
        "alt": "The outside of the christian church in Los Angeles Downtown.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170605031/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170605031_9e27b0611a_h.jpg",
        "width": 1160,
        "height": 1600,
        "alt": "The Disney theater in Los Angeles Downtown.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51169938317/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51169938317_0c84812854_h.jpg",
        "width": 1067,
        "height": 1600,
        "alt": "The golden-gate bridge at sunset with a golden-red sky.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170609926/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170609926_f432b83e65_h.jpg",
        "width": 1067,
        "height": 1600,
        "alt": "The rough coast at Land's end in San Francisco.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <h2 {...{
      "id": "austria"
    }}>{`Austria`}</h2>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51169943322/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51169943322_2532665095_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Overlooking a mountain range that is hidden by low-hanging fog clouds.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51171399364/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51171399364_7966e9ae12_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "The end of a bigger hill with a little lake in the foreground and the rest of the mountain range in the background.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51171708195/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51171708195_7e28d296f1_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "A small valley between two higher mountain ridges. Orange grass in the foreground blurry through the depth-of-field.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170844978/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170844978_43fc0b8aa1_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Looking into a valley in southern austria on a sunny day with a couple of clouds in the sky.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170614336/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170614336_3ef393358a_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "At nearly 3000m height a small house is on the mountain in the foreground and the massive mountains in the background are seemingly near but a huge valley is dividing that.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <h2 {...{
      "id": "croatia--slowenia"
    }}>{`Croatia & Slowenia`}</h2>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51171327954/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51171327954_36cdf66a05_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Small boat in a port of a small town in Croatia. The town is in the background and has a small destroyed castle at the top.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170773363/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170773363_c61cd16187_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Showing the water reflection of the sky, a small village on the right and the mountains in the background.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51169872412/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51169872412_786ddff0ea_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Blurry gras in the foreground, a small village at a lake in the middleground, and the mountains in the background.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170774268/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170774268_9a04a299df_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Waterfalls in the Plitvicer Lakes.",
        "data-drop-shadow": "true"
      }}></img>
    </a>
    <a {...{
      "href": "https://www.flickr.com/photos/192975453@N04/51170542986/in/datetaken/"
    }}>{`
  `}<img parentName="a" {...{
        "loading": "lazy",
        "src": "https://live.staticflickr.com/65535/51170542986_c288c5ecf2_b.jpg",
        "width": 1024,
        "height": 683,
        "alt": "Lake Bled in Slowenia with the alps in the background.",
        "data-drop-shadow": "true"
      }}></img>
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      